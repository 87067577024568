<template>
  <div class="text-left">
    <BaseHeader :title="'Editor Payments'"></BaseHeader>

    <v-card flat class="shadow-sm rounded">
        <v-card-text>

        </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Editor Payments",
};
</script>
